import React, { useEffect } from 'react'

import dynamic from 'next/dynamic'
import Image from 'next/image'

import AOS from 'aos'
import classnames from 'classnames/bind'
import VideoPlayer from 'react-player/vimeo'

import HomeApi from 'Services/home'

import appliedLogo from 'public/applied-materials.png'
import cadImage from 'public/cad.png'
import camImage from 'public/cam.png'
import logo from 'public/cnc-expert-logo.png'
import cncImage from 'public/cnc.png'
import screenshot from 'public/cncexpert-screenshot.jpg'
import disneyLogo from 'public/disney.png'
import isogrid from 'public/isogrid.png'
import mitsubishiLogo from 'public/mitsubishi.png'
import northropLogo from 'public/northrop-grumman.png'
import playIcon from 'public/play.png'
import verification from 'public/verification-image.jpg'

import styles from 'styles/Home.module.css'

import Heading from 'components/Heading'
import Link from 'components/Link'
import Seo from 'components/Seo'

const cx = classnames.bind(styles)

const ConditionalUser = dynamic(() => import('components/ConditionalUser'), { ssr: false })
const AccountNav = dynamic(() => import('components/AccountNav'), { ssr: false })
const AccordionSection = dynamic(() => import('components/Accordion/AccordionSection'), { ssr: false })

const share_info = {
  share_title: 'CNC Expert - Powered by Titans of CNC',
  share_description: '',
  share_image: 'https://cncexpert.com/share.jpg',
}

const Section = ({ classname, dark, children }) => {
  const classes = cx({
    section: true,
    [`${classname}`]: classname,
    dark: dark,
  })
  return (
    <div className={classes}>
      <div className="container">
        <div className={styles['section-wrapper']}>{children}</div>
      </div>
    </div>
  )
}

const LogoImage = React.memo(function LogoImage({ src }) {
  return (
    <Image
      src={src}
      alt="CNC Expert - Powered By Titans of CNC"
      priority
      sizes={`(min-width: 768px) 260px, 150px`}
      className={styles['hero-logo']}
    />
  )
})

export default function Home({ data }) {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,
    })
  }, [])

  return (
    <>
      <div className={styles['home']}>
        <Section classname={styles['hero']}>
          <div className={styles['hero-header']} data-aos="fade-in">
            <div className={styles['hero-logo-wrapper']}>
              <LogoImage src={logo} />
            </div>

            <div className={styles['hero-menu-wrapper']}>
              <ConditionalUser
                signedIn={<AccountNav />}
                signedOut={
                  <div className={styles['signin-link']}>
                    <Link href="/signup" primary button>
                      Get Started
                    </Link>
                    <Link href="/signin" underline spacing>
                      Sign In
                    </Link>
                  </div>
                }
              />
            </div>
          </div>

          <div className={styles['hero-content']}>
            <div data-aos="fade-in" data-aos-delay="200" data-aos-offset="-200">
              <Heading element="h1" center className={styles['hero-content-title']}>
                For machinists by machinists
              </Heading>
            </div>

            <div data-aos="fade-in" data-aos-delay="300">
              <p
                className={styles['hero-content-text']}
              >{`Get Certified, Get Hired, Hire People, Find Customers, and Find Vendors... all for FREE`}</p>
            </div>

            <Link href="/signup" primary button centered>
              Get Started
            </Link>
          </div>

          <div className={styles['image-bg']}>
            <Image
              src="/hero-bg.jpg"
              alt="Hero Background Image"
              layout="fill"
              objectFit="cover"
              objectPosition="20% 50%"
              quality={80}
            />
          </div>

          {/* <VideoBackground url="https://vimeo.com/487005470/a538548966" /> */}
        </Section>
        <Section classname={styles['intro']} dark>
          <div className={styles['intro-video']}>
            <VideoPlayer
              url={'https://vimeo.com/765889371/41d01219e5'}
              className={styles['video']}
              width="100%"
              height="100%"
              controls
              light="/cnc-expert-intro-thumbnail.jpg"
              playIcon={
                <div className={styles['intro-play-icon']}>
                  <Image src={playIcon} alt="Play CNC Expert Video" />
                </div>
              }
              config={{
                vimeo: {
                  playerOptions: {
                    width: 1280,
                    height: 720,
                    autoplay: true,
                  },
                },
              }}
            />
          </div>

          <div className={styles['intro-title']}>
            <div className={styles['intro-subtitle']} element="h2" size="h4">
              Our Mission
            </div>
            <Heading className={styles['intro-title-text']} element="h1" size="h2" center bold>
              Getting you jobs
            </Heading>

            <p className={styles['intro-text']}>
              {`Show off your work and capabilities. Get Customers, Find Vendors, and Advance your career by showing what you are truly capable of.`}
            </p>

            <div className={styles['intro-title-cta']}>
              <Link href="/signup" primary button>
                Get Started
              </Link>
            </div>
          </div>
        </Section>

        <Section classname={styles['build']}>
          <div className={styles['build-image']}>
            <Image src={screenshot} alt="Screenshot of Titan Gilroy's CNC Expert profile" />
          </div>

          <div className={styles['build-content']}>
            <div className="container">
              <Heading element="h1" size="h2" bold>
                Build Your Profile
              </Heading>

              <p>
                {`Forget resumes. THIS is what employers want. Showcase your projects, capabilities, availability, and achievements in a beautiful way that is quick and easy to understand.`}
              </p>
            </div>
          </div>
        </Section>

        <Section classname={styles['showcase']} dark>
          <div className={styles['showcase-image']}>
            <Image
              src={isogrid}
              alt="3D Model of project"
              sizes="(min-width: 768px) 50vw, (min-width: 992px) 500px, (min-width: 1200px) 1023px, 90vw"
            />
          </div>

          <div className={styles['showcase-content']}>
            <Heading element="h1" size="h2" bold>
              Showcase your work
            </Heading>

            <p>{`Your work and approach to solving problems is what sets you apart. Share the projects and parts you've made, go in-depth on your thought process and build a living portfolio of beautiful parts that will stay with you throughout your career.`}</p>

            <Link button href={`/global-gallery`}>
              Explore Global Gallery
            </Link>
          </div>
        </Section>

        <Section classname={styles['earn']}>
          <Heading element="h1" size="h2" bold>
            Earn Certificates
          </Heading>

          <p
            className={styles['centered-text']}
          >{`Show the world you're committed to this trade by leveling up. Submit the work you completed through our FREE Academies and earn certificates that clearly show what you're capable of.`}</p>

          <div className={styles['earn-images']}>
            <div className={styles['earn-image']}>
              <Image
                src={cadImage}
                alt="Image of 3d models"
                sizes="(min-width: 768px) 33vw, 90vw"
                placeholder={`blur`}
              />
              <Heading element="h3" size="h2" center>
                CAD
              </Heading>
            </div>
            <div className={styles['earn-image']}>
              <Image
                src={camImage}
                alt="Image of 3d models"
                sizes="(min-width: 768px) 33vw, 90vw"
                placeholder={`blur`}
              />
              <Heading element="h3" size="h2" center>
                CAM
              </Heading>
            </div>
            <div className={styles['earn-image']}>
              <Image
                src={cncImage}
                alt="Image of 3d models"
                sizes="(min-width: 768px) 33vw, 90vw"
                placeholder={`blur`}
              />
              <Heading element="h3" size="h2" center>
                CNC
              </Heading>
            </div>
          </div>
          <Link button href={`/certificates`}>
            Earn Certificates
          </Link>

          <div className={styles['verification']}>
            <Heading element="h1" size="h2" center>
              Industry Leading Verification Process
            </Heading>

            <div className={styles['verification-info']}>
              <div className={styles['verification-info-image']}>
                <Image src={verification} alt="Verfication Process" />
              </div>
              <div className={styles['verification-info-content']}>
                <ul className={styles['verification-info-list']}>
                  <li className={styles['verification-info-item']}>
                    <Heading element="h3" size="h4-5" tight lite>
                      Validation
                    </Heading>
                    <p
                      className={styles['verification-info-item-text']}
                    >{`Each part contains a unique set of parameters, dimensions, and features. Through our validation process we determine accuracy to verify the part against our master models and prints.`}</p>
                  </li>

                  <li className={styles['verification-info-item']}>
                    <Heading element="h3" size="h4-5" tight lite>
                      Part Assessment
                    </Heading>
                    <p
                      className={styles['verification-info-item-text']}
                    >{`Every certification contains a unique part assessment to validate skills and standards taught in the TITANS of CNC curriculum.`}</p>
                  </li>

                  <li className={styles['verification-info-item']}>
                    <Heading element="h3" size="h4-5" tight lite>
                      Certified Instructor
                    </Heading>
                    <p
                      className={styles['verification-info-item-text']}
                    >{`Our certified inspectors inspect, verify, and certify each CAD, CAM, and CNC part submitted.`}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Section>

        <Section classname={styles['endorsed']} dark>
          <Heading element="h1" size="h3" center>
            Endorsed By Industry Leaders
          </Heading>
          <div className={styles['endorsed-logos']}>
            <div className={styles['endorsed-logo']}>
              <Image src={disneyLogo} alt="Walt Disney" />
            </div>
            <div className={styles['endorsed-logo']}>
              <Image src={mitsubishiLogo} alt="Mitsubishi Hitachi" />
            </div>
            <div className={styles['endorsed-logo']}>
              <Image src={northropLogo} alt="Nortrop Grumman" />
            </div>
            <div className={styles['endorsed-logo']}>
              <Image src={appliedLogo} alt="Applied Materials" />
            </div>
          </div>
        </Section>

        <Section classname={styles['faqs']}>
          <Heading element="h2" size="h3" center>
            Frequently Asked Questions
          </Heading>

          <div className="container">
            <div className={styles['faqs-wrapper']}>
              <AccordionSection title="What is TITANS of CNC?">
                <div className={styles['accordion-content']}>
                  {`TITANS of CNC is a suite of manufacturing education sites and experiences. Founded by TITAN GILROY, an EXPERT in Advanced Manufacturing, we create advanced manufacturing curriculum for all generations of machinists and programmers. Visit titansofcnc.com to find out more about our education platforms.`}
                </div>
              </AccordionSection>
              <AccordionSection title="What is CNC Expert?">
                <div className={styles['accordion-content']}>
                  {`An advanced manufacturing portfolio for the entire manufacturing industry. Demonstrate your value, experience, and expertise in the form of real world parts certified by TITANS of CNC. Post projects, get FREE certifications, hire employees, find vendors, show customers what you're capable of, and browse a global user base of manufacturers across ALL industries. By machinists, for machinists.`}
                </div>
              </AccordionSection>
              <AccordionSection title="How does it work?">
                <div className={styles['accordion-content']}>
                  {`Create your custom personalized portfolio to display your unique projects and certifications. Design, Program, and Machine real world parts and certify your work throughout the process. TEAM TITAN will verify your solid model, simulate your program, and visually inspect your parts.`}
                </div>
              </AccordionSection>
              <AccordionSection title="How do I begin?">
                <div className={styles['accordion-content']}>
                  {`Click "Get Started" to create your free account and start building your profile.`}
                  <br />
                  <br />
                  <Link href="/signup" primary button>
                    Get Started
                  </Link>
                </div>
              </AccordionSection>
              <AccordionSection title="How do I get customers from this site?">
                <div className={styles['accordion-content']}>
                  {`Build a beautiful profile that shows your capabilities, experience, and ability to solve problems. Then share it on your social media channels. Right now, our #1 focus is building the site, but as it grows we will market CNC EXPERT to companies to find vendors, employees, etc. You can help us by sharing and posting to your sphere of influence... keep having conversations and keep telling everyone how amazing the platform truly is.`}
                </div>
              </AccordionSection>
              <AccordionSection title="How much does a certificate cost?">
                <div className={styles['accordion-content']}>
                  {`Certification is FREE for CAD and CAM! Please review the Terms of Service for more information about CNC Inspection and the fees associated with this process.`}
                </div>
              </AccordionSection>
              <AccordionSection title="What is the benefit of a certification from TITANS of CNC?">
                <div className={styles['accordion-content']}>
                  {`The TITANS of CNC: Academy Curriculum is endorsed by thousands of educators, industry professionals, and top manufacturing companies. Kennametal, Doosan Machine Tools, Mastercam, Solidworks, Blaser Swisslube, Haimer, Mitutoyo, Fanuc, Tormach, Schunk, and LNS all back the Academy and its mission to train the Next Generation of Machinists. Your CNC EXPERT Certification proves your comprehension of fundamental design, programming, manufacturing techniques, and increases your ability to solve problems for current and future employers.`}
                </div>
              </AccordionSection>
              <AccordionSection title="How do I earn a CAD, CAM, or CNC Certificate?">
                <div className={styles['accordion-content']}>
                  {`Complete the TITAN BUILDING BLOCKS or the TITAN ROCKET Series on the TITANS of CNC: Academy and submit evidence of your work to our TEAM OF EXPERTS. Your solid model or NC program will be evaluated 48-72 hours after we receive it. Machined parts must be inspected by TITANS of CNC or a CNC Certified Inspector, then the results of the inspection will be verified by our TEAM. When the process is completed and you’re approved, you will receive your CERTIFICATE.`}
                </div>
              </AccordionSection>
              <AccordionSection title="What file formats do you accept?">
                <div className={styles['accordion-content']}>
                  {`For CAD certifications we accept .STEP files only. Every CAD application should support exporting in this format.`}
                </div>
              </AccordionSection>
              <AccordionSection title="How do I contact support?">
                <div className={styles['accordion-content']}>
                  {`Need help? Send us an email, chat with us on the TITANS of CNC: Academy, or post your question on the TITANS Forum.`}
                </div>
              </AccordionSection>
            </div>
          </div>
        </Section>

        <Section classname={styles['become']} dark>
          <Heading element="h3" center>
            Become a CNC Expert
          </Heading>

          <div className={styles['become-cta']}>
            <div className={styles['become-content']}>
              Start building your custom profile and exploring available certificates.
            </div>

            <Link href="/signup" primary button>
              Get Started
            </Link>
          </div>
        </Section>
      </div>
      <Seo {...share_info} />
    </>
  )
}

export const getStaticProps = async (ctx) => {
  const HomeData = HomeApi.getHomeData()

  return {
    props: {
      data: HomeData.data,
    },
  }
}
