import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'

const Seo = (props) => {
  const title = props?.share_title ? `${props?.share_title}` : `CNC Expert - Powered by TITANS of CNC`

  const description = props?.share_description || ``

  const image = props?.share_image || `${process.env.NEXT_PUBLIC_URL}/share.jpg`

  const type = props?.share_type || 'website'

  let url = props?.share_url || ''

  const router = useRouter()
  const pathname = router?.pathname
  const path = router?.components?.[pathname]?.resolvedAs

  if (path?.startsWith('/dashboard/project')) {
    url = path.replace('/dashboard', '')
  }

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title: title,
          description: description,
          images: [
            {
              url: image,
              width: 1200,
              height: 600,
            },
          ],
          url: `${process.env.NEXT_PUBLIC_URL}${url}`,
          type: type,
          site_name: 'CNC Expert - Powered By TITANS of CNC',
        }}
        twitter={{
          site: '@titangilroy',
          cardType: 'summary_large_image',
        }}
      />
    </>
  )
}

export default Seo
